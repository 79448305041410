import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ lang, meta, title, description }) => {
    const { site } = useStaticQuery(graphql`
    {
        site {
          siteMetadata {
            title
            description
            twitter
            keywords
            siteUrl
          }
        }
      }      
    `)
    const metadata = site.siteMetadata;
    const determinedTitle = title || metadata.title;
    const determinedDescription = description || metadata.description;
    const { pathname } = useLocation();

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={determinedTitle}
            titleTemplate={determinedTitle}
            link={pathname ? [
                {
                    rel: "canonical",
                    href: `${metadata.siteUrl}${pathname}`,
                }
            ] : []}
            meta={[
                {
                    name: `description`,
                    content: determinedDescription,
                },
                {
                    name: "keywords",
                    content: metadata.keywords.join(","),
                },
                {
                    property: `og:title`,
                    content: determinedTitle,
                },
                {
                    property: `og:description`,
                    content: determinedDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:creator`,
                    content: metadata.twitter,
                },
                {
                    name: `twitter:title`,
                    content: determinedTitle,
                },
                {
                    name: `twitter:description`,
                    content: determinedDescription,
                },
            ]
                .concat(meta)}
        />
    )
}

Seo.defaultProps = {
    lang: 'en',
    meta: [],
    title: null,
    description: null
}

Seo.propTypes = {
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    description: PropTypes.string
}

export default Seo
